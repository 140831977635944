import axios from 'axios';

let http = axios.create({ withCredentials: true });

// Whenever our client receives a 401 unauthorized error, force the user back to login
http.interceptors.response.use(res => res, err => {
    if(err.response.status === 401){
        window.location = '/unauthorized';
    }
    else {
        let message = err;
        if(err.response.status === 400){
            if(err.response.data && err.response.data.Message){
                message = err.response.data.Message;
            }
        }
        console.log(err);
        return Promise.reject(err);
    }
});

export default http;