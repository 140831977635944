// Imports
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import http from "../service";
import styled from "styled-components";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Cookies from "js-cookie";
import LocationListing from "./LocationListing";

// styled components
const CheckBoxLabel = styled.label`
    padding-right: 10px;
`;
const UseCurrentLocationText = styled.p`
    :hover {
        cursor: pointer;
    }
`;

// switch marker depending on group type
const MarkerGroupSwitch = (group) => {
    const careCenterMarker =
        "/ResourcePackages/Talon/assets/dist/images/red-marker.png";
    const clinicalAssociateSiteMarker =
        "/ResourcePackages/Talon/assets/dist/images/cyan-marker.png";
    const supportGroupMarker =
        "/ResourcePackages/Talon/assets/dist/images/blue-marker.png";
    const clinicalTrialMarker =
        "/ResourcePackages/Talon/assets/dist/images/orange-marker.png";

    switch (group) {
        case "1":
            return careCenterMarker;
        case "16":
            return clinicalAssociateSiteMarker;
        case "2":
            return supportGroupMarker;
        case "8":
            return clinicalTrialMarker;
        default:
            return careCenterMarker;
    }
};

const FindLocation = (props) => {
    const [containerEl] = useState(document.createElement("div"));
    const [locations, setLocation] = React.useState([]);
    const [marker, setMarker] = React.useState(null);
    const [mapCenter, setMapCenter] = React.useState(null);
    const [currentLocation, setCurrentLocation] = React.useState(null);
    const [searchValue, setSearchValue] = React.useState(null);
    const [zipCode, setZipCode] = React.useState(null);
    const [take, setTake] = React.useState(0);
    const [code, setCode] = React.useState(null);
    const [totalCount, setTotalCount] = React.useState(0);
    const [zoomLevel, setZoomLevel] = React.useState(11);
    const locationSettings =
        document.getElementById("location-settings") &&
        JSON.parse(document.getElementById("location-settings").innerHTML);

    locationSettings["CareCenter"] =
        locationSettings["CareCenter"] === "True" ? true : false;
    locationSettings["SupportGroup"] =
        locationSettings["SupportGroup"] === "True" ? true : false;
    locationSettings["VirtualGroup"] =
        locationSettings["VirtualGroup"] === "True" ? true : false;
    locationSettings["ClinicalTrial"] =
        locationSettings["ClinicalTrial"] === "True" ? true : false;

    const [groupType, setGroupType] = React.useState({
        1: locationSettings["CareCenter"],
        2: locationSettings["SupportGroup"],
        3: locationSettings["VirtualGroup"],
        8: locationSettings["ClinicalTrial"],
    });
    const mapKey = locationSettings && locationSettings["map"];

    // helper to get latitude and longitude
    const getLatLng = (lat, lng) => {
        if (lat && lng) {
            return {
                lat: lat,
                lng: lng,
            };
        }
    };

    const groupTypeObj = {
        1: true,
        2: true,
        3: true,
        8: true,
    };

    const targetMaker = (location) => {
        const url = `${locationSettings["locationDetailPage"]}/${location}`;
        window.open(url, "_blank");
    };

    const setMarkerActive = (location, markerId) => {
        setMarker(markerId);
        setZoomLevel(15);
        setMapCenter(
            getLatLng(location.Address.Latitude, location.Address.Longitude)
        );
    };

    const setCurrentLocationObj = (location) => {
        return {
            Latitude: location.Latitude,
            Longitude: location.Longitude,
            ZipCode: location.ZipCode,
        };
    };

    const containerStyle = {
        height: "10rem",
    };

    const center = {
        lat: 41.8781136,
        lng: -87.6297982,
    };

    const scaledMarker = {
        width: 100,
        height: 100,
    };

    const defaultMarkerSize = {
        width: 50,
        height: 50,
    };

    const pffStyle = [
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [
                { visibility: "off" }, // remove points of interest
            ],
        },
    ];

    const mapOptions = {
        editable: false,
        markersWontHide: true,
        mapTypeControlOptions: false,
        fullscreenControl: false,
        styles: pffStyle,
        mapTypeControlOptions: {
            position: "BOTTOM_RIGHT",
        }, // remove satelite option
    };

    const htmlDecode = (input) => {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    };

    useEffect(() => {
        document.getElementById("findLocation").appendChild(containerEl);
        // get current position
        const cookie = Cookies.get("sf-mc-med");
        navigator.geolocation.getCurrentPosition(
            function (position) {
                setMapCenter(
                    getLatLng(
                        position.coords.latitude,
                        position.coords.longitude
                    )
                );
                if (!cookie) {
                    getCurrentLocation(
                        position.coords.latitude,
                        position.coords.longitude
                    );
                } else {
                    let parseCookie = JSON.parse(cookie);
                    setCurrentLocation(
                        getLatLng(
                            parseCookie["Latitude"],
                            parseCookie["Longitude"]
                        )
                    );
                    let zip = `${parseCookie["ZipCode"]}`;
                    setZipCode(zip);
                    getLocations(zip, groupType);
                }
            },
            function (error) {
                console.error(
                    `Geolocation Error (${error.code}): ${error.message}`
                );
                setCode("GeolocationError");
            }
        );

        return () => {
            document.getElementById("findLocation").removeChild(containerEl);
        };
    }, [containerEl]);

    useEffect(() => {
        if (searchValue) {
            getLocations(searchValue, groupType);
        } else if (Cookies.get("sf-mc-med")) {
            let cookie = Cookies.get("sf-mc-med");
            let parseCookie = JSON.parse(cookie);
            getLocations(parseCookie["ZipCode"], groupType);
        }
    }, [groupType, take]);

    const getLocations = (zipcode, groupType) => {
        setZipCode(zipcode);
        setZoomLevel(9);
        setMarker(null);
        var params = getParams(zipcode, groupType);
        let url = `/api/medical-care/search?${params}`;

        http.get(url)
            .then((res) => {
                setTotalCount(res.data.TotalCount);
                setLocation(res.data.Locations);
                if (res.data.Locations) {
                    setMapCenter(
                        getLatLng(
                            res.data.Locations[0].Address?.Latitude,
                            res.data.Locations[0].Address?.Longitude
                        )
                    );
                }
                if (res.data.Locations !== null) {
                    if (res.data.Locations.length <= 3) {
                        setCode("NoResults");
                    } else {
                        setCode("OK");
                    }
                } else {
                    setCode("NoneSelected");
                }
            })
            .finally(() => {});
    };
    const getParams = (zipCode, groupType) => {
        const paramMap = new Map();
        paramMap.set("zip", zipCode);
        paramMap.set("take", take || 25);
        paramMap.set("radius", locationSettings["radius"]);
        if (groupType) {
            paramMap.set("careCenter", groupType["1"]);
            paramMap.set("supportGroup", groupType["2"]);
            paramMap.set("virtualLocations", groupType["3"]);
            paramMap.set("clinicalTrial", groupType["8"]);
        }
        return Array.from(paramMap)
            .map(
                ([key, value]) =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
            )
            .join("&");
    };

    const getCurrentLocation = (lat, lng) => {
        http.get(`/api/medical-care/geolocation?lat=${lat}&lng=${lng}`).then(
            (res) => {
                setCurrentLocation(getLatLng(lat, lng));
                if (res.data.Address) {
                    Cookies.set(
                        "sf-mc-med",
                        setCurrentLocationObj(res.data.Address),
                        { expires: 1, sameSite: "strict", secure: true }
                    );
                    getLocations(`${res.data.Address.ZipCode}`, groupType);
                }
            }
        );
    };

    const getCurrentMapPosition = (location, zipCode) => {
        if (location) {
            setMapCenter(location);
            getLocations(`${zipCode}`, groupType);
            setSearchValue("");
        } else {
            setCode("GeolocationError");
        }
    };

    const handleCheckBoxChanges = (checkbox) => {
        setTake(25);
        let isChecked = checkbox.target.checked;
        if (groupTypeObj[checkbox.target.value]) {
            setGroupType((oldGroupType) => {
                let newstate = {};
                newstate[1] = oldGroupType[1];
                newstate[2] = oldGroupType[2];
                newstate[3] = oldGroupType[3];
                newstate[8] = oldGroupType[8];

                newstate[checkbox.target.value] = isChecked;
                return newstate;
            });
        }
    };

    return ReactDOM.createPortal(
        <div className="locator">
            <div className="locator__body">
                <div className="locator__title-wrap">
                    <h2 class="locator__title">
                        {locationSettings &&
                            htmlDecode(locationSettings["title"])}
                    </h2>
                </div>
                <div className="locator__content">
                    <div class="locator__controls">
                        <div class="locator__filters">
                            <CheckBoxLabel>
                                <input
                                    type="checkbox"
                                    name="MedicalCenterBoxes"
                                    value="1"
                                    onChange={(e) => handleCheckBoxChanges(e)}
                                    checked={groupType["1"]}
                                />
                                <span>Care Center Network</span>
                            </CheckBoxLabel>
                            <CheckBoxLabel>
                                <input
                                    type="checkbox"
                                    name="MedicalCenterBoxes"
                                    value="2"
                                    onChange={(e) => handleCheckBoxChanges(e)}
                                    checked={groupType["2"]}
                                />
                                <span>Support Group</span>
                            </CheckBoxLabel>
                            <CheckBoxLabel>
                                <input
                                    type="checkbox"
                                    name="MedicalCenterBoxes"
                                    value="3"
                                    onChange={(e) => handleCheckBoxChanges(e)}
                                    checked={groupType["3"]}
                                />
                                <span>Virtual Group</span>
                            </CheckBoxLabel>
                            <CheckBoxLabel>
                                <input
                                    type="checkbox"
                                    name="MedicalCenterBoxes"
                                    value="8"
                                    onChange={(e) => handleCheckBoxChanges(e)}
                                    checked={groupType["8"]}
                                />
                                <span>Clinical Trial</span>
                            </CheckBoxLabel>
                        </div>
                        <div class="locator__search">
                            <input
                                className="locator__search-input"
                                type="text"
                                value={searchValue}
                                placeholder={"Enter Zip Code"}
                                onChange={(e) => setSearchValue(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        setSearchValue(e.target.value);
                                        setTake(25);
                                        getLocations(searchValue, groupType);
                                    }
                                }}
                            />
                            <button
                                className="button button--action locator__search-submit"
                                type="button"
                                onClick={() => {
                                    setTake(25);
                                    getLocations(searchValue, groupType);
                                }}
                            >
                                Search
                            </button>
                        </div>

                        <button
                            type="button"
                            className="locator__use-location"
                            onClick={() =>
                                getCurrentMapPosition(currentLocation, zipCode)
                            }
                        >
                            <svg
                                className="location__icon"
                                role="presentation"
                                aria-hidden="true"
                            >
                                <use href="/assets/icons/svgdefs.svg#icon-target"></use>
                            </svg>
                            Use Current Location
                        </button>
                    </div>

                    <LocationListing
                        code={code}
                        locations={locations}
                        take={take}
                        totalCount={totalCount}
                        zipCode={zipCode}
                        setMarkerActive={setMarkerActive}
                        detailPage={locationSettings["locationDetailPage"]}
                    />
                    <p>
                        {totalCount > 0 && take < totalCount && (
                            <>
                                <button
                                    type="button"
                                    className="button button--action"
                                    onClick={() => {
                                        if (take < totalCount) {
                                            if (take + 10 > totalCount) {
                                                setTake(totalCount);
                                            } else {
                                                setTake(take + 10);
                                            }
                                        }
                                    }}
                                >
                                    Load More
                                </button>
                            </>
                        )}
                    </p>
                </div>
            </div>

            <div className="locator__map">
                {mapKey && (
                    <LoadScript googleMapsApiKey={mapKey}>
                        <GoogleMap
                            center={mapCenter ? mapCenter : center}
                            zoom={zoomLevel}
                            options={mapOptions}
                            mapContainerStyle={containerStyle}
                        >
                            {locations &&
                                locations.map((l, i) => {
                                    return (
                                        <Marker
                                            key={i}
                                            position={getLatLng(
                                                l.Address.Latitude,
                                                l.Address.Longitude
                                            )}
                                            onClick={() =>
                                                targetMaker(l.UrlName)
                                            }
                                            icon={{
                                                url: MarkerGroupSwitch(
                                                    l.GroupType.PersistedValue
                                                ),
                                                scaledSize:
                                                    marker == i
                                                        ? scaledMarker
                                                        : defaultMarkerSize,
                                            }}
                                        />
                                    );
                                })}
                        </GoogleMap>
                    </LoadScript>
                )}
            </div>
        </div>,
        containerEl
    );
};

export default FindLocation;
