import logo from './logo.svg';
import './App.css';

// Components
import {
  FindLocation
} from './Components'

var findLocationWidget = document.getElementById('findLocation');

const App = () => {
  return (
    <>
      {findLocationWidget && <FindLocation></FindLocation>}
    </>
  )
}

export default App;
