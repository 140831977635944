// Imports
import React from "react";
import styled from "styled-components";

const ListInfoImage = styled.img`
    width: 50px;
`;
const GroupTypeSwitch = (group) => {
    switch (group) {
        case "1":
            return <span>Care Center</span>;
        case "16":
            return <span>Clinical Associate</span>;
        case "2":
            return <span>Support Group</span>;
        case "8":
            return <span>Clinical Trial</span>;
        default:
            return <span>Care Center</span>;
    }
};

const GroupTypeDefaultImage = (group) => {
    const defaultImage =
        "/ResourcePackages/Talon/assets/dist/images/medical-center-default.png";
    const careCenterDefaultImage =
        "/ResourcePackages/Talon/assets/dist/images/care-center-default.png";
    const clinicalTrialDefaultImage =
        "/ResourcePackages/Talon/assets/dist/images/clinical-trials-default.png";
    switch (group) {
        case "1":
        case "16":
            return careCenterDefaultImage;
        case "2":
            return defaultImage;
        case "8":
            return clinicalTrialDefaultImage;
        default:
            return defaultImage;
    }
};

const renderAddress = (address) => {
    let formattedAddress = "";

    if (address) {
        if (address.Street !== "" && address.Street !== null) {
            formattedAddress += address.Street + ", ";
        }

        if (address.City !== "" && address.City !== null) {
            formattedAddress += address.City + ", ";
        }

        if (address.StateCode !== "" && address.StateCode !== null) {
            formattedAddress += address.StateCode + " ";
        }

        if (address.ZipCode !== "" && address.ZipCode !== null) {
            formattedAddress += address.ZipCode;
        }
    }

    return formattedAddress;
};

const LocationListing = (props) => {
    const detailPage = props.detailPage;
    const SHOW = "location__distance";
    const HIDE = "";

    return (
        <>
            {props.code == "GeolocationError" && (
                <div class="locator__no-results">
                    <div className="margin-bottom">
                        <strong>
                            We are unable to access your exact location
                        </strong>
                    </div>
                    <p>
                        To find Care Center or Support Group locations turn on
                        Location Services or enter a Zip Code.
                    </p>
                </div>
            )}
            {props.code == "OK" && (
                <strong className="locator__showing">
                    Showing {props.take} of {props.totalCount} locations near{" "}
                    {props.zipCode}
                </strong>
            )}
            {props.code == "NoResults" && (
                <div className="locator__no-results">
                    <p>
                        <strong>
                            We're sorry. There are no Care Centers, Support
                            Groups, and/or Clinical Trials in your area; however
                            here are the nearest locations based on your search.
                        </strong>
                    </p>
                </div>
            )}
            {props.code == "NoneSelected" && (
                <div className="locator__no-results">
                    <p>
                        <strong>
                            We're sorry. You have not selected a search filter.
                            Please select one to receive results.
                        </strong>
                    </p>
                </div>
            )}
            <ul class="location-list">
                {props.locations &&
                    props.locations.map((l, i) => {
                        return (
                            <li
                                className="location"
                                id={`mc-${i}`}
                                onClick={() => props.setMarkerActive(l, i)}
                            >
                                <div class="location__media">
                                    {l.LogoUrl ? (
                                        <img
                                            src={l.LogoUrl}
                                            alt={l.Title}
                                            className="location__img"
                                        />
                                    ) : (
                                        <ListInfoImage
                                            src={GroupTypeDefaultImage(
                                                l.GroupType.PersistedValue
                                            )}
                                            alt=""
                                        />
                                    )}
                                </div>
                                <div className="location__body">
                                    <div className="location__type">
                                        {GroupTypeSwitch(
                                            l.GroupType.PersistedValue
                                        )}
                                    </div>
                                    <h3 className="location__title">
                                        {l.Title}
                                    </h3>
                                    <div className="location__address">
                                        {renderAddress(l.Address)}
                                    </div>
                                    <div className="location__info">
                                        <a
                                            style={{
                                                display: l.PhoneNumber
                                                    ? "inline"
                                                    : "none",
                                            }}
                                            href={`tel:${l.PhoneNumber}`}
                                            className="location__phone"
                                            aria-label={`Phone number for ${l.Title} is ${l.PhoneNumber}`}
                                        >
                                            <svg
                                                className="location__icon"
                                                role="presentation"
                                                aria-hidden="true"
                                            >
                                                <use href="/assets/icons/svgdefs.svg#icon-phone-ringing"></use>
                                            </svg>
                                            {l.PhoneNumber}
                                        </a>
                                        <div
                                            className={
                                                l.PhoneNumber ? SHOW : HIDE
                                            }
                                        >
                                            <svg
                                                className="location__icon"
                                                role="presentation"
                                                aria-hidden="true"
                                                focusable="false"
                                            >
                                                <use href="/assets/icons/svgdefs.svg#icon-location"></use>
                                            </svg>
                                            {Math.round(l.Miles * 100) / 100}{" "}
                                            miles away
                                        </div>
                                    </div>
                                    <div className="location__link-wrap">
                                        <a
                                            href={`${detailPage}/${l.UrlName}`}
                                            target="_blank"
                                            className="location__link"
                                        >
                                            See Details{" "}
                                            <span className="sr-only">
                                                for {l.Title}
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </li>
                        );
                    })}
            </ul>
        </>
    );
};

export default LocationListing;
